:root {
  --rs-bg-active: #e65017;
  --rs-btn-primary-bg: #e65017;
  --rs-btn-link-text: #e65017;
  --rs-primary-700: #e65017;
  --rs-primary-100: #ffe4cc;
  --rs-primary-500: #e65017;
  --rs-primary-600: #cf4815;
  --rs-primary-800: #a13810;
}

.rs-picker-popup .rs-calendar .rs-calendar-time-dropdown-column > ul {
  height: 180px;
  padding-bottom: 20px;
}